import { DateTime } from "luxon";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as t from "io-ts";
import api, { createDecoder } from "../../api";
import * as sessionActions from "../session";
import { getTrailersAndRoutesResponseT } from "dora-contracts";

interface Trailer {
  id: string;
  number: string;
  alias: string | null;
}

interface Stop {
  city: string | null;
}

export interface Route {
  id: string;
  createdAt?: string | null;
  routeName: string | null;
  trailerId: string | null;
  destinationDate: string | null;
  completed: boolean;
  firstStop: Stop;
  lastStop: Stop;
}

interface State {
  status: "UNINITIALIZED" | "READY" | "LOAD_ERROR";
  trailers: Trailer[];
  routes: Route[];
}

const initialState: State = {
  status: "UNINITIALIZED",
  trailers: [],
  routes: [],
};

export const init = createAsyncThunk(
  "app/route-login/init",
  async (_: void, thunkApi) => {
    return await api(thunkApi).get({
      url: "/drivers-api/trailers",
      decoder: createDecoder(getTrailersAndRoutesResponseT),
    });
  }
);

const loginRouteResponseT = t.strict({
  sessionId: t.string,
  routeId: t.string,
});

export const loginRoute = createAsyncThunk(
  "app/route-login/login",
  async (input: { routeId: string | null; trailerId: string }, thunkApi) => {
    const result = await api(thunkApi).post({
      url: "/drivers-api/sessions",
      body: { ...input, time: DateTime.now().toISO() },
      decoder: createDecoder(loginRouteResponseT),
    });
    thunkApi.dispatch(sessionActions.setSession(result.sessionId));
    return {
      ...result,
      trailerId: input.trailerId,
    };
  }
);

const slice = createSlice({
  name: "app/route-login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(init.fulfilled, (state, action) => {
        state.status = "READY";
        state.trailers = action.payload.trailers;
        state.routes = action.payload.routes;
      })
      .addCase(init.rejected, (state) => {
        state.status = "LOAD_ERROR";
      });
    // .addCase(loginRoute.fulfilled, (state, action) => {
    // state.currentSessionId = action.payload.sessionId;
    // });
  },
});

export default slice.reducer;
