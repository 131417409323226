import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";

let savedUrl: string | undefined;

const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      savedUrl = event.url;
      const url = new URL(event.url);
      const slug = url.pathname + url.search;

      if (slug) {
        navigate(slug);
      }
      // If no match, do nothing - let regular routing logic take over
      return () => {
        App.removeAllListeners();
      };
    });
  }, []);

  return null;
};

export default AppUrlListener;
