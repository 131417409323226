import { useEffect, useState } from "react";
import { isMobile } from "./mobile";
import { Geolocation } from "@capacitor/geolocation";

export interface Location {
  lat: number;
  lon: number;
  accuracy: number;
}

export const getPosition = async (): Promise<Location | null> => {
  if (isMobile) {
    const coordinates = await Geolocation.getCurrentPosition();
    return {
      lat: coordinates.coords.latitude,
      lon: coordinates.coords.longitude,
      accuracy: coordinates.coords.accuracy,
    };
  }

  return new Promise((resolve) => {
    const geolocation = navigator.geolocation;
    if (!geolocation) {
      return resolve(null);
    }
    geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
          accuracy: position.coords.accuracy,
        });
      },
      (_error) => {
        return resolve(null);
      },
      { enableHighAccuracy: true }
    );
  });
};

// Currently not used, but maybe in the future
export const useCurrentAddress = () => {
  const [placeName, setPlaceName] = useState("Waiting");
  useEffect(() => {
    getPosition().then((pos) => {
      console.log("POS", pos);
      if (!pos) {
        return;
      }
      const geocoder = new google.maps.Geocoder();
      return geocoder
        .geocode({ location: { lat: pos.lat, lng: pos.lon } })
        .then((response) => {
          if (response.results.length) {
            const result = response.results[0];
            console.log({ result });
            setPlaceName(result.formatted_address);
          }
        });
    });
  }, []);
  return placeName;
};
