import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import * as actions from "../../../ducks/app/session";
import { useAppDispatch } from "../../../redux-store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Camera, CameraResultType } from "@capacitor/camera";
import { isMobile } from "../../../helpers/mobile";
import { decode } from "base64-arraybuffer";
import { Button } from "@mui/material";

type Image = {
  file: Blob;
  name: string;
};

const AddImage = ({
  sessionId,
  cargoId,
  stopId,
  added,
  imagesForStop,
  onSuccess,
}: {
  sessionId: string;
  cargoId: string;
  stopId: string;
  added: boolean;
  imagesForStop: number;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation("stopPage");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const uploadPhoto = (image: Image) => {
    const formData = new FormData();
    formData.append("cargoId", cargoId);
    formData.append("stopId", stopId);
    formData.append("documentType", "POD");
    formData.append("file", image.file, image.name);
    setLoading(true);
    dispatch(actions.uploadFile2({ sessionId, formData })).finally(() => {
      setLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    });
  };

  const takePhoto = async () => {
    const cameraPhoto = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
    });
    if (!cameraPhoto.base64String) {
      return;
    }
    const blob = new Blob([new Uint8Array(decode(cameraPhoto.base64String))], {
      type: `image/${cameraPhoto.format}`,
    });

    uploadPhoto({
      file: blob,
      name: `mobile-upload-${new Date().toISOString()}.${cameraPhoto.format}`,
    });
  };

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) {
      return;
    }
    uploadPhoto({
      file: files[0],
      name: files[0].name,
    });
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <label>
          <LoadingButton
            loading={loading}
            variant="outlined"
            fullWidth
            size="large"
            component="span"
            startIcon={<CameraAltOutlinedIcon />}
            onClick={() => {
              if (isMobile) {
                takePhoto();
              }
            }}
          >
            {t("takePhoto")}
            {!isMobile && (
              <input
                capture="environment"
                style={{ display: "none" }}
                type="file"
                id="take-picture"
                accept="image/*"
                onChange={onChange}
              />
            )}
            {added && <CheckIcon />}
            <span>{imagesForStop ? `(${imagesForStop})` : ""}</span>
          </LoadingButton>
        </label>
      </form>
      {/* On mobile a native picker is shown to select camera from gallery */}
      {!isMobile && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <label>
            <LoadingButton
              loading={loading}
              variant="outlined"
              fullWidth
              size="large"
              component="span"
              startIcon={<CollectionsOutlinedIcon />}
            >
              <span>{t("addPhoto")}</span>
              <input
                style={{ display: "none" }}
                type="file"
                id="take-picture"
                accept="image/*"
                onChange={onChange}
              />
              {added && <CheckIcon />}
              <span>{imagesForStop ? `(${imagesForStop})` : ""}</span>
            </LoadingButton>
          </label>
        </form>
      )}
    </>
  );
};

export default AddImage;
